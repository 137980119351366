<script setup>
    import { inject, computed } from "vue";

    const currentSyncDefinition = inject('CurrentSyncDefinition');

    const currentSyncProgress = computed(() => {
        return currentSyncDefinition.value?.currentSyncProgress;
    });

    const currentSyncProgressPending = computed(() => {
        return currentSyncProgress.value?.currentSyncProgressPending ?? 0;
    });

    const currentSyncProgressSuccess = computed(() => {
        return currentSyncProgress.value?.currentSyncProgressSuccess ?? 0;
    });

    const currentSyncProgressError = computed(() => {
        return currentSyncProgress.value?.currentSyncProgressError ?? 0;
    });
</script>

<template>
    <div class="d-flex gap-2 align-items-center">
        <div class="progress-stacked w-100" style="height:15px;">
            <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressSuccess" aria-valuemin="0"
                aria-valuemax="100" :style="{ width: `${currentSyncProgressSuccess}%` }">
                <div class="progress-bar bg-success"></div>
            </div>

            <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressError" aria-valuemin="0"
                aria-valuemax="100" :style="{ width: `${currentSyncProgressError}%` }">
                <div class="progress-bar bg-danger"></div>
            </div>

            <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressPending" aria-valuemin="0"
                aria-valuemax="100" :style="{ width: `${currentSyncProgressPending}%` }">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
            </div>
        </div>
        <i v-if="currentSyncProgressError > 0" class="bi bi-exclamation-circle-fill text-danger"
            :title="$t('Expand the dialog to see more')"></i>
    </div>
</template>

<style scoped>
    .progress-bar {
        transition: width 1s ease-in-out;
    }
</style>
